import React from 'react';
//import './Pagination.css'; // Optional: Import your CSS for styling

const Pagination = ({ currentPage, totalPages, paginate }) => {
  const getPaginationItems = () => {
    const items = [];

    // Always show the first page
    items.push(1);

    // Show dots if the current page is greater than 3
    if (currentPage > 3) {
      items.push('...');
    }

    // Add pages around the current page
    const startPage = Math.max(2, currentPage - 1); // Start from 2 or currentPage - 1
    const endPage = Math.min(totalPages - 1, currentPage + 1); // End at totalPages - 1 or currentPage + 1

    for (let i = startPage; i <= endPage; i++) {
      items.push(i);
    }

    // Show dots if the current page is less than totalPages - 2
    if (currentPage < totalPages - 2) {
      items.push('...');
    }

    // Always show the last page
    if (totalPages > 1) {
      items.push(totalPages);
    }

    return items;
  };

  return (
    <div className="pagination">
      {getPaginationItems().map((item, index) =>
        item === '...' ? (
          <span key={index} className="dots">
            {item}
          </span>
        ) : (
          <button
            key={item}
            onClick={() => paginate(item)}
            className={currentPage === item ? 'active' : ''}
          >
            {item}
          </button>
        )
      )}
    </div>
  );
};

export default Pagination; // Ensure the component is exported
