// FlightCard.js
import React, { useState, useEffect } from 'react';
import delta from '../../Resources/assest/img/listing/delta.png';
import plane2 from '../../Resources/assest/img/listing/plane2.png';
import { constant } from "../../constant"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faPlaneArrival, faClock, faInfoCircle, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';

const extractNameFromDeeplink = (link) => {
    try {
      const url = new URL(link);
      const hostname = url.hostname; // e.g., "www.farehutz.us"
      
      // Check if hostname starts with "www."
      if (hostname.startsWith('www.')) {
        const parts = hostname.split('.');
        if (parts.length > 1) {
          return parts[1]; // Return the part between 'www.' and the top-level domain
        }
      } else {
        const parts = hostname.split('.');
        return parts.length > 0 ? parts[0] : 'unknown'; // Return first part if it doesn't start with "www."
      }
    } catch (error) {
      console.error('Error parsing URL:', error);
      return 'unknown'; // Fallback name on error
    }
  };

const FlightCard = ({ isLoding, price, leg1, leg2, deeplinkUrl, similarItineraries, index, allMeta}) => {
    const [isLoading, setIsLoading] = useState(isLoding);
    useEffect(() => {
        setIsLoading(isLoding);
        console.log("itinery",isLoding)
    }, [isLoding]); // Update state if the prop changes
    const extractNameFromDeeplink = (link) => {
    try {
      const url = new URL(link);
      const hostname = url.hostname; // e.g., "www.farehutz.us"
      
      // Check if hostname starts with "www."
      if (hostname.startsWith('www.')) {
        const parts = hostname.split('.');
        if (parts.length > 1) {
          return parts[1]; // Return the part between 'www.' and the top-level domain
        }
      } else {
        const parts = hostname.split('.');
        return parts.length > 0 ? parts[0] : 'unknown'; // Return first part if it doesn't start with "www."
      }
    } catch (error) {
      console.error('Error parsing URL:', error);
      return 'unknown'; // Fallback name on error
    }
  };
    const calculateTotalTime = (segments) => {
        if (segments.length === 0) return null;
    
        // Get the departure time of the first segment
        const firstDepartureTime = new Date(segments[0].departureDateTime);
        // Get the arrival time of the last segment
        const lastArrivalTime = new Date(segments[segments.length - 1].arrivalDateTime);
    
        // Calculate the total duration in milliseconds
        const durationInMs = lastArrivalTime - firstDepartureTime;
    
        // Convert milliseconds to hours and minutes
        const totalHours = Math.floor(durationInMs / (1000 * 60 * 60));
        const totalMinutes = Math.floor((durationInMs % (1000 * 60 * 60)) / (1000 * 60));
    
        return (
          <p className="mb-0 text-center ws532">
            {totalHours}h {totalMinutes}m
          </p>
        );
      };
    //   const handleItineraryClick = (index) => {
    //     console.log("Itinerary position:", index);
    //     console.log(allMeta)
    //     window.open(deeplinkUrl, '_blank'); // Open the deeplink in a new tab
    //   };
    const handleItineraryClick = async (index) => {
        console.log("Itinerary position:", index);
        console.log(allMeta);
      
        // Extract the sitename from the deeplink URL
        const url = new URL(deeplinkUrl);
        const hostnameParts = url.hostname.replace(/^www\./, '').split('.');
        const sitename = hostnameParts[0]; // e.g., 'farehutz' from 'www.farehutz.us'
      
        let matchedMeta = null;
        const matchedMetaArray = [];
      
        // Check if allMeta is an array
        if (Array.isArray(allMeta.data)) {
          // Find a matching site in allMeta based on sitename
          matchedMeta = allMeta.data.find(meta => meta.sitename.toLowerCase() === sitename.toLowerCase());
        } else if (typeof allMeta === 'object' && allMeta.data) {
          // If allMeta is an object with a `data` key, iterate over its items
          for (const meta of allMeta.data) {
            if (meta.sitename.toLowerCase() === sitename.toLowerCase()) {
              matchedMeta = meta;
              break;
            }
          }
        }
      
        if (matchedMeta) {
          console.log(`Match found for sitename: ${sitename}`);
          matchedMetaArray.push(matchedMeta);
          window.open(deeplinkUrl, '_blank'); // Open the deeplink in a new tab
        } else {
          console.log(`No match found for sitename: ${sitename}`);
        }
        console.log(matchedMetaArray)

        try{
            const clickReportSave = await fetch(`${constant.baseUrl}api/website/save-click`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(matchedMeta),
            });
    
            // Check response status
            if (!clickReportSave.ok) {
                console.error("Failed to fetch onlyDeeplink. Status:", clickReportSave.status);
                return;
            } else {
                console.log("Success", clickReportSave.data)
            }
        } catch {

        }

        console.log("Matched Metadata Array:", matchedMetaArray);
      };
      
  return (
    <>
                    {/* <!-- ................itnery section start.................. --> */}
                    <div class="bg-white mt-3 swret5">
                        <div class="row px-3 align-items-center" onClick={handleItineraryClick}>
                            <div class="col-lg-8 py-3 dfrtgr6">
                                <div class="mai-itn">
                                    <div class="row align-items-center">
                                        <div class="col-lg-3">
                                            <img class="ahr83" src={`https://imgfolders.com/farehutz/${leg1.segments[0].airlineCode}.png`} alt="" />
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="djr9">
                                                <div class="row align-items-center">
                                                    <div class="col-4 col-lg-4 ">
                                                        <p class="mb-0 text-end ere5e">{new Date(leg1.segments[0].departureDateTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false})}</p>
                                                        <div class="rela r6w3 ms-auto">
                                                            <p class="mb-0 text-end dt5r4">{leg1.segments[0].departureAirportCode}</p>
                                                            <div class="kirjr4">
                                                                <p class="mb-0 dr52s">{leg1.segments[0].aircraftCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 col-lg-4 text-center">
                                                        <p class="mb-0 text-center ws532">{calculateTotalTime(leg1.segments)}</p>
                                                        <img class=" text-center" src={plane2}
                                                            alt="" />
                                                        <p class="mb-0 text-center dt34e">{leg1.segments.length === 1 ? "Direct" : `${leg1.segments.length - 1} Stop${leg1.segments.length > 2 ? 's' : ''}`}</p>
                                                    </div>
                                                    <div class="col-4 col-lg-4">
                                                        <p class="mb-0 ere5e">{new Date(leg1.segments[leg1.segments.length - 1].arrivalDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</p>
                                                        <div class="rela r6w3">
                                                            <p class="mb-0 text-end dt5r4">{leg1.segments[leg1.segments.length - 1].arrivalAirportCode}</p>
                                                            <div class="kirjr4">
                                                                <p class="mb-0 dr52s">{leg1.segments[leg1.segments.length - 1].departureAirportCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mai-itn mt-3">
                                    <div class="row align-items-center">
                                        <div class="col-lg-3">
                                            <img class="ahr83" src={`https://imgfolders.com/farehutz/${leg2.segments[0].airlineCode}.png`} alt="" />
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="djr9">
                                                <div class="row align-items-center">
                                                    <div class="col-4 col-lg-4 ">
                                                        <p class="mb-0 text-end ere5e">{new Date(leg2.segments[0].departureDateTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false})}</p>
                                                        <div class="rela r6w3 ms-auto">
                                                            <p class="mb-0 text-end dt5r4">{leg2.segments[0].departureAirportCode}</p>
                                                            <div class="kirjr4">
                                                                <p class="mb-0 dr52s">{leg2.segments[0].aircraftCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 col-lg-4 text-center">
                                                        <p class="mb-0 text-center ws532">{calculateTotalTime(leg2.segments)}</p>
                                                        <img class=" text-center" src={plane2}
                                                            alt="" />
                                                        <p class="mb-0 text-center dt34e">{leg2.segments.length === 1 ? "Direct" : `${leg2.segments.length - 1} Stop${leg2.segments.length > 2 ? 's' : ''}`}</p>
                                                    </div>
                                                    <div class="col-4 col-lg-4">
                                                        <p class="mb-0 ere5e">{new Date(leg1.segments[leg1.segments.length - 1].arrivalDateTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</p>
                                                        <div class="rela r6w3">
                                                            <p class="mb-0 text-end dt5r4">{leg1.segments[leg1.segments.length - 1].arrivalAirportCode}</p>
                                                            <div class="kirjr4">
                                                                <p class="mb-0 dr52s">{leg1.segments[leg1.segments.length - 1].aircraftCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 py-3">
                                <div class="farejy">
                                    <div class="d-flex align-items-center justify-content-center">
                                        {isLoading ? (
                                        <div class="spinner12 center">
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                            <div class="spinner-blade"></div>
                                        </div>
                                        ) : null}
                                        <p class="mb-0 wr35">${price.totalAmount}{/*<sup>00</sup>*/}</p>
                                    </div>
                                    <p class="mb-0 edrt54t5">{extractNameFromDeeplink(deeplinkUrl)}</p>
                                    <button class="erbhu49">Select <i class="fa fa-arrow-right"
                                            aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        {/* <!-- .............accordian start............................ --> */}
                        {similarItineraries.length > 0 ? (
                        <div class="row dt453w1 align-items-center">
                            <div class="col-10 col-lg-10 py-2 ps-3">
                                <div class="hnrfu786 d-flex align-items-center">
                                    {similarItineraries.map((item, index) => {
                                    <p class="mb-0 jiw94" key={index}>{item.deeplinkUrl} ${item.price.totalAmount}{/*<sup>00</sup>*/}</p>
                                })}
                                </div>
                            </div>
                            <div class="col-2 col-lg-2 py-2 text-end pe-3">
                                <i class="fa fa-angle-down " aria-hidden="true"></i>
                            </div>
                        </div>
                        ): null}
                       <div class="dhr95">
                        <div class="row dt453w align-items-center py-2 ">
                            <div class="col-6 col-lg-6 ps-3">
                                <p class="mb-0 de64k">Friendztravel</p>
                            </div>
                            <div class="col-6 col-lg-6 pe-3">
                                <div class="d-flex align-items-center justify-content-end">
                                    <p class="mb-0 me-2">$345.<sup>00</sup></p>
                                    <button class="erbhu492">Select </button>
                                </div>
                            </div>
                        </div>
                        <div class="row dt453w align-items-center py-2">
                            <div class="col-6 col-lg-6 ps-3">
                                <p class="mb-0 de64k">Friendztravel</p>
                            </div>
                            <div class="col-6 col-lg-6 pe-3">
                                <div class="d-flex align-items-center justify-content-end">
                                    <p class="mb-0 me-2">${price.totalAmount}<sup>00</sup></p>
                                    <button class="erbhu492">Select </button>
                                </div>
                            </div>
                        </div>
                        <div class="row dt453w align-items-center py-2">
                            <div class="col-6 col-lg-6 ps-3">
                                <p class="mb-0 de64k">Friendztravel</p>
                            </div>
                            <div class="col-6 col-lg-6 pe-3">
                                <div class="d-flex align-items-center justify-content-end">
                                    <p class="mb-0 me-2">$345.<sup>00</sup></p>
                                    <button class="erbhu492">Select </button>
                                </div>
                            </div>
                        </div>
                       </div>
                        {/* <!-- .............accordian end............................ --> */}
                    </div>
                    {/* <!-- ................itnery section end.................. --> */}
                
    </>
  );
};

export default FlightCard;
