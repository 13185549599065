// This is the controller file where one change reflects to everywhere.

const SITE_CONFIG = {
    apiIP: 'https://www.fareslist.com',
    // apiIP: 'http://localhost',
    siteName: 'rawfares',
    siteID: '16',
  };
  
export default SITE_CONFIG;
  