/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from '../Pages/Home';
import Social from '../Pages/Social';
import Privacy from '../Pages/privacy';
import Contactus from '../Pages/Contactus';
import Disclaimer from '../Pages/Disclaimer';
import TermsCondition from '../Pages/TermsCondition';
import Aboutus from '../Pages/Aboutus';
import Airlines from '../Pages/Airlines';
import City from '../Pages/City';
import Destination from '../Pages/Destination';
import Sitemap from '../Pages/Sitemap';
import EuropeContinent from '../Pages/EuropeContinent';
import AfricanContinent from '../Pages/AfricanContinent';
import AustraliaContinent from '../Pages/AustraliaContinent';
import AsiaContinent from '../Pages/AsiaContinent';
import SouthAmerica from '../Pages/SouthAmerica';
import NorthAmerica from '../Pages/NorthAmerica';
import BaggageFee from '../Pages/BaggageFee';
import DallasDenver from '../Pages/DallasDenver';
import LosLas from '../Pages/LosLas';
import SeatleHonolulu from '../Pages/SeatleHonolulu';
import MiamiMexico from '../Pages/MiamiMexico';
import SfoLon from '../Pages/SfoLon';
import NotFound from '../Pages/404';
import DeepLink from '../Components/DeepLink/Deeplink';
import FlightSearchUI from '../Pages/Interface/Listing';
import MarketMeta from '../Pages/MarketMeta/MarketMeta';

const ScrollToTop = () => {
   const { pathname } = useLocation();
 
   useEffect(() => {
     window.scrollTo(0, 0);
   }, [pathname]);
 
   return null;
 };

const Routers = ({ setHeaderColor }) => {
 
   return (
      <>
      <ScrollToTop />
      
      <Routes>
         {/* <Route path='/*' element={<ScrollToTop />} /> */}
         <Route path='/home' element={<Navigate to='/'/>} />
         
         {/* <Route path='/flight-result' Component={flightResult} /> */}
         <Route exact path='/' element={<Home />} />
         <Route path='/listing' element={<FlightSearchUI/>} />
         <Route path='/about-us' element={<Aboutus />} />
         <Route path='/contactus' element={<Contactus />} />
         <Route path='/terms-and-conditions' element={<TermsCondition />} />
         <Route path='/disclaimer' element={<Disclaimer />} />
         <Route path='/privacy-policy' element={<Privacy />} />
         <Route path='/social' element={<Social />} />
         <Route path='/airlines' element={<Airlines />} />
         <Route path='/city' element={<City />} />
         <Route path='/destination' element={<Destination />} />
         <Route path='/sitemap' element={<Sitemap />} />
         <Route path='/europe' element={<EuropeContinent />} />
         <Route path='/africa' element={<AfricanContinent />} />
         <Route path='/australia' element={<AustraliaContinent />} />
         <Route path='/asia' element={<AsiaContinent />} />
         <Route path='/south-america' element={<SouthAmerica />} />
         <Route path='/north-america' element={<NorthAmerica />} />
         <Route path='/baggage-fee' element={<BaggageFee />} />
         <Route path='/dallas-denver' element={<DallasDenver />} />
         <Route path='/losangeles-lasvegas' element={<LosLas />} />
         <Route path='/seatle-honolulu' element={<SeatleHonolulu />} />
         <Route path='/miami-mexico' element={<MiamiMexico />} />
         <Route path='/sanfrancisco-london' element={<SfoLon />} />
         <Route path='/searchFlight/?' element={<DeepLink />} />
         <Route path='*' element={<NotFound />} />
      </Routes>
      </>
   )
}

export default Routers