// Filters.js
import React, { useState, useEffect } from 'react';
import airlinesName from '../../Components/AirlineName/AirlineName';
import axios from "axios";
import filter from '../../Resources/assest/img/listing/filter.png'
import stop from '../../Resources/assest/img/listing/stop.png'
import depart from '../../Resources/assest/img/listing/depart.png'
import clock1 from '../../Resources/assest/img/listing/0-6.png'
import clock2 from '../../Resources/assest/img/listing/6-12.png'
import clock3 from '../../Resources/assest/img/listing/12-6.png'
import clock4 from '../../Resources/assest/img/listing/6-0.png'
import returnn from '../../Resources/assest/img/listing/return.png'
import fares from '../../Resources/assest/img/listing/fares.png'
import airline from '../../Resources/assest/img/listing/airline.png'

const Filters = ({ flights, filteredResultsCount, onFilterChange }) => {
    const [stops, setStops] = useState({
        direct: false,
        oneStop: false,
        twoPlusStops: false,
    });

    const [priceRange, setPriceRange] = useState([0, 1000]);

     // State for selected airlines
     const [selectedAirlines, setSelectedAirlines] = useState([]);
     const [selectedLayoverAirports, setSelectedLayoverAirports] = useState([]);
     const [layoverAirportsWithNames, setLayoverAirportsWithNames] = useState({});
     const [returnLayoverAirportsWithNames, setReturnLayoverAirportsWithNames] = useState({});
     const [selectedReturnLayoverAirports, setSelectedReturnLayoverAirports] = useState([]);
     const [showMore, setShowMore] = useState(false);
     const [showMoreAirports, setShowMoreAirports] = useState(false);
     const [showMoreReturnAirports, setShowMoreReturnAirports] = useState(false);

    // State for selected time slots
    const [selectedTimeSlots, setSelectedTimeSlots] = useState(new Set());
    const [selectedTimeSlotsForReturn, setSelectedTimeSlotsForReturn] = useState(new Set());

    // Function to fetch airport name from API based on airport code
    // const fetchAirportName = async (airport) => {
    //     try {
    //         console.log("PAyload",airport)
    //         const response = await axios.get(
    //             `https://www.tgsapi.com/flights/CrossAirportList?data=${airport}&authcode=farehuts`
    //         );
    //         console.log("Direct response",response)
    //         const airportData = response.data.find(
    //             (airport) => airport.airportCode === airport
    //         );
    //         console.log("API Response",airportData)
    //         return airportData ? airportData.airportName : airport; // Return airport name or fallback to code
    //     } catch (error) {
    //         console.error("Error fetching airport data:", error);
    //         return airport; // Fallback in case of an error
    //     }
    // };

    const timeSlots = [
        { label: "00-06", start: 0, end: 6, image: clock1 },
        { label: "06-12", start: 6, end: 12, image: clock2 },
        { label: "12-18", start: 12, end: 18, image: clock3 },
        { label: "18-24", start: 18, end: 24, image: clock4 },
    ];
    const timeSlotsForReturn = [
        { label: "00-06", start: 0, end: 6, image: clock1 },
        { label: "06-12", start: 6, end: 12, image: clock2 },
        { label: "12-18", start: 12, end: 18, image: clock3 },
        { label: "18-24", start: 18, end: 24, image: clock4 },
    ];

    const handleStopChange = (e) => {
        const { name, checked } = e.target;
        setStops((prev) => ({ ...prev, [name]: checked }));
    };

    // const handleTimeSlotChange = (index) => {
    //     const updatedSlots = new Set(selectedTimeSlots);
    //     if (updatedSlots.has(index)) {
    //       updatedSlots.delete(index);
    //     } else {
    //       updatedSlots.add(index);
    //     }
    //     setSelectedTimeSlots(updatedSlots);
    //   };
      
    //   const handleTimeSlotForReturnChange = (index) => {
    //     const updatedSlots = new Set(selectedTimeSlotsForReturn);
    //     if (updatedSlots.has(index)) {
    //       updatedSlots.delete(index);
    //     } else {
    //       updatedSlots.add(index);
    //     }
    //     setSelectedTimeSlotsForReturn(updatedSlots);
    //   };
    const handleTimeSlotChange = (index) => {
        setSelectedTimeSlots(new Set([index]));
    };

    // Allow selecting only one time slot for return
    const handleTimeSlotForReturnChange = (index) => {
        setSelectedTimeSlotsForReturn(new Set([index]));
    };

    // Function to calculate minimum price for each stop type
    const calculatePriceForStops = (numStops) => {
        const filteredFlights = flights.filter(flight => {
            const leg1Stops = flight.leg1.segments.length > 0 ? flight.leg1.segments.length - 1 : 0;
            const leg2Stops = flight.leg2 ? (flight.leg2.segments.length > 0 ? flight.leg2.segments.length - 1 : 0) : 0;
            const totalStops = leg1Stops + leg2Stops;

            return totalStops === numStops;
        });

        if (filteredFlights.length > 0) {
            return Math.min(...filteredFlights.map(flight => flight.price.totalAmount));
        }

        return null; // If no flights match, return null
    };

    // Get prices for each stop type
    const priceForDirect = calculatePriceForStops(0); // 0 stops
    const priceForOneStop = calculatePriceForStops(1); // 1 stop
    const priceForTwoPlusStops = calculatePriceForStops(2); // 2 or more stops


     // Function to extract unique airlines and their prices
     const getAirlinesWithLowestPrices = () => {
        const airlines = {};

        flights.forEach((flight) => {
            const airlineCode = flight.leg1.segments[0].airlineCode;
            const price = flight.price.totalAmount; // Adjust this to your actual price field

            // Check if leg2 exists and has the same airline
            if (flight.leg2 && flight.leg2.segments[0].airlineCode === airlineCode) {
                // If airline is not added yet, or the price is lower, update it
                if (!airlines[airlineCode] || price < airlines[airlineCode].price) {
                    airlines[airlineCode] = { code: airlineCode, price };
                }
            }
        });

        return Object.values(airlines); // Return array of airlines with lowest prices
    };

    const airlinesWithLowestPrices = getAirlinesWithLowestPrices();

     // Extract layover airports (departure airports for `segment[1]` of `leg1`)
     const getLayoverAirports = () => {
        const layoverAirports = {};

        flights.forEach((flight) => {
            if (flight.leg1.segments.length > 1) {
                const layoverAirportCode = flight.leg1.segments[1].departureAirportCode;
                if (!layoverAirports[layoverAirportCode]) {
                    layoverAirports[layoverAirportCode] = layoverAirportCode; // Store airport code
                }
            }
        });

        return Object.values(layoverAirports); // Return array of unique layover airport codes
    };

    const layoverAirports = getLayoverAirports();
    

    const getReturnLayoverAirports = () => {
        const returnLayoverAirports = {};

        flights.forEach((flight) => {
            if (flight.leg2.segments.length > 1) {
                const returnLayoverAirportCode = flight.leg2.segments[1].departureAirportCode;
                if (!returnLayoverAirports[returnLayoverAirportCode]) {
                    returnLayoverAirports[returnLayoverAirportCode] = returnLayoverAirportCode; // Store airport code
                }
            }
        });

        return Object.values(returnLayoverAirports); // Return array of unique layover airport codes
    };

    const returnLayoverAirports = getReturnLayoverAirports();

    // Handle airline selection change
    const handleAirlineChange = (e) => {
        const { value, checked } = e.target;
        setSelectedAirlines((prev) =>
            checked ? [...prev, value] : prev.filter((airline) => airline !== value)
        );
    };

    // useEffect(() => {
    //     const fetchAllLayoverAirportNames = async () => {
    //         const updatedAirports = {};

    //         // Fetch all airport names in parallel using Promise.all
    //         const airportNamePromises = layoverAirports.map(async (airport) => {
    //             const airportName = await fetchAirportName(airport);
    //             updatedAirports[airport] = airportName;
    //         });

    //         // Wait for all promises to resolve
    //         await Promise.all(airportNamePromises);

    //         // Update state only after all names are fetched
    //         setLayoverAirportsWithNames(updatedAirports);
    //         console.log(layoverAirportsWithNames)
    //     };
    //     console.log(layoverAirportsWithNames)
    //     if (layoverAirports.length > 0) {
    //         fetchAllLayoverAirportNames();
    //     }
    // }, [layoverAirports]);

    // useEffect(() => {
    //     const fetchLayoverAirports = async () => {
    //         const updatedAirports = {};
    //         for (const airport of returnLayoverAirports) {
    //             const airportName = await fetchAirportName(airport);
    //             updatedAirports[airport] = airportName;
    //         }
    //         setReturnLayoverAirportsWithNames(updatedAirports);
    //     };

    //     if (returnLayoverAirports.length > 0) {
    //         console.log("it is called for return", returnLayoverAirportsWithNames)
    //         fetchLayoverAirports();
    //     }
    // }, [returnLayoverAirports]);

    // Handle layover airport filter changes
    const handleLayoverAirportChange = (e) => {
        const { value, checked } = e.target;
        setSelectedLayoverAirports((prev) =>
            checked ? [...prev, value] : prev.filter((airport) => airport !== value)
        );
    };

    const handleReturnLayoverAirportChange = (e) => {
        const { value, checked } = e.target;
        setSelectedReturnLayoverAirports((prev) =>
            checked ? [...prev, value] : prev.filter((airport) => airport !== value)
        );
    };

      

    // Function to filter flights
    const filterFlights = () => {
        return flights.filter((flight) => {
            const leg1Stops = flight.leg1.segments.length > 0 ? flight.leg1.segments.length - 1 : 0;
            const leg2Stops = flight.leg2 ? (flight.leg2.segments.length > 0 ? flight.leg2.segments.length - 1 : 0) : 0;
            const totalStops = leg1Stops + leg2Stops;

            const matchesStops =
                (stops.direct && totalStops === 0) ||
                (stops.oneStop && totalStops === 1) ||
                (stops.twoPlusStops && totalStops > 1) ||
                (!stops.direct && !stops.oneStop && !stops.twoPlusStops);

            const price = flight.price.totalAmount; // Adjust this to your actual price field
            const matchesPrice = price >= priceRange[0] && price <= priceRange[1];

            const departureTime = new Date(flight.leg1.segments[0].departureDateTime).getHours();
            const matchesTimeSlot =
                selectedTimeSlots.size === 0 || // Skip time slot matching if none are selected
                Array.from(selectedTimeSlots).some(slot => {
                    const { start, end } = timeSlots[slot];
                    return departureTime >= start && departureTime < end;
                });

            const returnTime = flight.leg2 ? new Date(flight.leg2.segments[0].departureDateTime).getHours() : null;
            const matchesTimeSlotForReturn =
                flight.leg2 && (selectedTimeSlotsForReturn.size === 0 || // Skip if none selected for return
                Array.from(selectedTimeSlotsForReturn).some(slot => {
                    const { start, end } = timeSlotsForReturn[slot];
                    return returnTime >= start && returnTime < end;
                }));

                
                
                const airlineCode = flight.leg1.segments[0].airlineCode;
                console.log(airlineCode)
                console.log(selectedAirlines)
                const matchesAirline =
                    selectedAirlines.length === 0 || selectedAirlines.includes(airlineCode);

                    const layoverAirport =
                flight.leg1.segments.length > 1
                    ? flight.leg1.segments[1].departureAirportCode
                    : null;
            const matchesLayoverAirport =
                selectedLayoverAirports.length === 0 ||
                (layoverAirport && selectedLayoverAirports.includes(layoverAirport));

                const returnLayoverAirport =
                flight?.leg2?.segments.length > 1
                    ? flight?.leg2?.segments[1].departureAirportCode
                    : null;
            const matchesReturnLayoverAirport =
                selectedReturnLayoverAirports.length === 0 ||
                (returnLayoverAirport && selectedReturnLayoverAirports.includes(returnLayoverAirport));

            return matchesStops && matchesPrice && matchesTimeSlot && matchesTimeSlotForReturn && matchesAirline && matchesLayoverAirport && matchesReturnLayoverAirport;
        });
    };

    useEffect(() => {
        const filteredFlights = filterFlights();
        onFilterChange(filteredFlights); // Pass the filtered flights to the parent
    }, [stops, priceRange, selectedTimeSlots, selectedTimeSlotsForReturn, selectedAirlines, selectedLayoverAirports, selectedReturnLayoverAirports, flights]);

    const visibleAirlines = showMore ? airlinesWithLowestPrices : airlinesWithLowestPrices.slice(0, 5);

    const handleSliderChange = (newRange) => {
        setPriceRange(newRange);
      };

    const handleReset = () => {
        setStops({ direct: false, oneStop: false, twoPlusStops: false });
        setPriceRange([0, 1000]);
        setSelectedTimeSlots(new Set());
        setSelectedTimeSlotsForReturn(new Set());
        setSelectedAirlines([]);
        setSelectedLayoverAirports([])
    };

    return (
        <div class="col-lg-3 hutd67 det4r566" >

            <div class=" dxgewet sedtgsea">
                <div class="d-flex justify-content-end ">
                    <p class="uyre6k" onClick={handleReset}>Reset</p>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={filter} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Filter results</h6>
                        <p class="font-re-s gtuj">Found {filteredResultsCount} Result.</p>
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={stop} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Stop(s)</h6>
                        <div class="gtuj1">
                            <div class="d-flex font-re-s align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <input class="form-check-input dfyhdry me-1" name="direct" type="checkbox" checked={stops.direct} onChange={handleStopChange}
                                        id="flexCheckChecked" />
                                    <p class="mb-0 ms-1">Direct</p>
                                </div>
                                <p class="mb-0 ms-1">{priceForDirect !== null ? `$${priceForDirect.toFixed(2)}` : 'N/A'}{/*<sup>00</sup>*/}</p>
                            </div>
                            <div class="d-flex font-re-s align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <input class="form-check-input dfyhdry me-1" name="oneStop" type="checkbox" checked={stops.oneStop} onChange={handleStopChange}
                                        id="flexCheckChecked" />
                                    <p class="mb-0 ms-1">1 stop</p>
                                </div>
                                <p class="mb-0 ms-1">{priceForOneStop !== null ? `$${priceForOneStop.toFixed(2)}` : 'N/A'}{/*<sup>00</sup>*/}</p>
                            </div>
                            <div class="d-flex font-re-s align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <input class="form-check-input dfyhdry me-1" type="checkbox" name="twoPlusStops" checked={stops.twoPlusStops} onChange={handleStopChange}
                                        id="flexCheckChecked" />
                                    <p class="mb-0 ms-1">2+ stop</p>
                                </div>
                                <p class="mb-0 ms-1">{priceForTwoPlusStops !== null ? `$${priceForTwoPlusStops.toFixed(2)}` : 'N/A'}{/*<sup>00</sup>*/}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={depart} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Departure From</h6>
                        <p class="font-re-s gtuj">New York - USA</p>
                        <div class="row gtuj">
                            {timeSlots.map((slot, index) => (
                                <div class="col-lg-3 ps-0" key={index}>
                                    <div class="ugbj">
                                        <button className={`dertotg ${selectedTimeSlots.has(index.toString()) ? 'selected dorht' : ''}`}
                                            onClick={() => handleTimeSlotChange(index.toString())}>
                                            <img src={slot.image} alt={slot.label} />
                                        </button>
                                        <p class="swtsws456">{slot.label}</p>
                                    </div>
                                </div>
                            ))}
                            {/* <div class="col-lg-3 ps-0">
                        <div class="ugbj">
                            <button class="dertotg ">
                                <img src={clock2} alt="" />
                            </button>
                            <p class="swtsws456">06-12</p>
                        </div>
                    </div>
                    <div class="col-lg-3 ps-0">
                        <div class="ugbj">
                            <button class="dertotg ">
                                <img src={clock3} alt="" />
                            </button>
                            <p class="swtsws456">12-06</p>
                        </div>
                    </div>
                    <div class="col-lg-3 ps-0">
                        <div class="ugbj">
                            <button class="dertotg ">
                                <img src={clock4} alt="" />
                            </button>
                            <p class="swtsws456">06-00</p>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={returnn} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Return From</h6>
                        <p class="font-re-s gtuj">Denver - USA</p>
                        <div class="row gtuj">
                            {timeSlotsForReturn.map((slot, index) => (
                                <div class="col-lg-3 ps-0">
                                    <div class="ugbj">
                                        <button className={`dertotg ${selectedTimeSlotsForReturn.has(index.toString()) ? 'selected dorht' : ''}`}
                                            onClick={() => handleTimeSlotForReturnChange(index.toString())}>
                                            <img src={slot.image} alt={slot.label} />
                                        </button>
                                        <p class="swtsws456">{slot.label}</p>
                                    </div>
                                </div>
                            ))}
                            {/* <div class="col-lg-3 ps-0">
                                <div class="ugbj ">
                                    <button class="dertotg ">
                                        <img src={clock2} alt="" />
                                    </button>
                                    <p class="swtsws456">06-12</p>
                                </div>
                            </div>
                            <div class="col-lg-3 ps-0">
                                <div class="ugbj ">
                                    <button class="dertotg ">
                                        <img src={clock3} alt="" />
                                    </button>
                                    <p class="swtsws456">12-06</p>
                                </div>
                            </div>
                            <div class="col-lg-3 ps-0">
                                <div class="ugbj ">
                                    <button class="dertotg ">
                                        <img src={clock4} alt="" />
                                    </button>
                                    <p class="swtsws456">06-00</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={fares} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Fares</h6>
                        <div class="d-flex justify-content-between  ">
                            <p class="aopi46">{`$${priceRange[0]}`}{/*<sup>00</sup>*/}</p>
                    <p class="aopi46">{`$${priceRange[1]}`}{/*<sup>00</sup>*/}</p>
                            {/* <input
                                type="range"
                                min="0"
                                max="1000"
                                value={priceRange[0]}
                                onChange={(e) => setPriceRange([e.target.value, priceRange[1]])}
                            />
                            <input
                                type="range"
                                min="0"
                                max="1000"
                                value={priceRange[1]}
                                onChange={(e) => setPriceRange([priceRange[0], e.target.value])}
                            /> */}
                            {/* <span>{`$${priceRange[0]} - $${priceRange[1]}`}</span> */}
                        </div>
                        <div class="rela">
                            <div class="stgt-line"></div>
                            <div class="cirlfjg" style={{ left: `${(priceRange[0] / 1000) * 100}%` }} draggable="true" onDrag={(e) => handleSliderChange([e.clientX / window.innerWidth * 1000, priceRange[1]])}></div>
                            <div 
                              className="cirlfjg" 
                              style={{ left: `${(priceRange[1] / 1000) * 100}%` }} 
                              draggable="true"
                              onDrag={(e) => handleSliderChange([priceRange[0], e.clientX / window.innerWidth * 1000])}
                            />
                        </div>
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={airline} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Airlines</h6>
                        {visibleAirlines.map((airline, index) => (
                        <div class="d-flex font-re-s align-items-center justify-content-between" key={index}>
                            <div class="d-flex align-items-center">
                                <input class="form-check-input dfyhdry me-1" type="checkbox" value={airline.code} onChange={handleAirlineChange}
                                    id="flexCheckChecked" checked={selectedAirlines.includes(airline.code)} />
                                <p class="mb-0 ms-1">{airlinesName[airline.code]}</p>
                            </div>
                            <p class="mb-0 ms-1">${airline.price.toLocaleString()}{/*<sup>00</sup>*/}</p>
                        </div>
                        ))}
                        {airlinesWithLowestPrices.length > 5 && (
                    <button
                        className="btn btn-link p-0 mt-2"
                        onClick={() => setShowMore((prev) => !prev)}
                    >
                        {showMore ? "Show Less" : "Show More"}
                    </button>
                )}
                        {/* <div class="d-flex font-re-s align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                    id="flexCheckChecked" checked="" />
                                <p class="mb-0 ms-1">Delta Airlines</p>
                            </div>
                            <p class="mb-0 ms-1">$47,081.<sup>00</sup></p>
                        </div>
                        <div class="d-flex font-re-s align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                    id="flexCheckChecked" checked="" />
                                <p class="mb-0 ms-1">Delta Airlines</p>
                            </div>
                            <p class="mb-0 ms-1">$47,081.<sup>00</sup></p>
                        </div>
                        <div class="d-flex font-re-s align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                    id="flexCheckChecked" checked="" />
                                <p class="mb-0 ms-1">Delta Airlines</p>
                            </div>
                            <p class="mb-0 ms-1">$47,081.<sup>00</sup></p>
                        </div>
                        <div class="d-flex font-re-s align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                    id="flexCheckChecked" checked="" />
                                <p class="mb-0 ms-1">Delta Airlines</p>
                            </div>
                            <p class="mb-0 ms-1">$47,081.<sup>00</sup></p>
                        </div> */}
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={depart} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Departure Layover airport</h6>
                        {(showMoreAirports
                    ? layoverAirports
                    : layoverAirports.slice(0, 5)
                ).map((airport, index) => (
                        <div class="d-flex font-re-s align-items-center" key={index}>
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value={airport} onChange={handleLayoverAirportChange}
                                id="flexCheckChecked" checked={selectedLayoverAirports.includes(airport)} />
                            <p class="mb-0 ms-1">{layoverAirportsWithNames[airport] || airport}</p>
                        </div>
                        ))}
                        {layoverAirports.length > 5 && (
                    <button
                        className="btn btn-link"
                        onClick={() => setShowMoreAirports(!showMoreAirports)}
                    >
                        {showMoreAirports ? "Show Less" : "Show More"}
                    </button>
                )}
                        {/* <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Dallas Fort Worth</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Ronald Reagan W. National</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Charlotte Douglas</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Portland</p>
                        </div> */}
                    </div>
                </div>
                <div class="right-box d-flex align-items-start ">
                    <img class="icon-lerft" src={returnn} alt="" />
                    <div class="right-inner-box">
                        <h6 class="mb-2 font-clrf">Return Layover airport</h6>
                        {(showMoreReturnAirports
                    ? returnLayoverAirports
                    : returnLayoverAirports.slice(0, 5)
                ).map((airport, index) => (
                        <div class="d-flex font-re-s align-items-center" key={index}>
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value={airport} onChange={handleReturnLayoverAirportChange}
                                id="flexCheckChecked" checked={selectedReturnLayoverAirports.includes(airport)} />
                            <p class="mb-0 ms-1">{returnLayoverAirports[airport] || airport}</p>
                        </div>
                ))}
                {returnLayoverAirports.length > 5 && (
                    <button
                        className="btn btn-link"
                        onClick={() => setShowMoreReturnAirports(!showMoreReturnAirports)}
                    >
                        {showMoreReturnAirports ? "Show Less" : "Show More"}
                    </button>
                )}
                        {/* <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Ronald Reagan W. National</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Charlotte Douglas</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Dallas Fort Worth</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Charlotte Douglas</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Hartsfield Jackson Atlanta</p>
                        </div>
                        <div class="d-flex font-re-s align-items-center">
                            <input class="form-check-input dfyhdry me-1" type="checkbox" value=""
                                id="flexCheckDefault" />
                            <p class="mb-0 ms-1">Seattle Tacoma</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filters;
